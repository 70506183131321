@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'e-Ukraine';
  src: url('./e-Ukraine/e-Ukraine-UltraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
